import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { Avatar } from '@mui/material';

import './Home.scss';
import { createPost } from '../../api';
import usePosts from '../../hooks/usePosts';
import Header from '../../Components/Header';
import { SendRoundedIcon } from '../../utils/icons';
import { Post, PostBody, LoginForm } from '../../types';

type Text = Post['text'];
type ImageUrl = Post['imgUrl'];

function Home(): React.ReactElement {
  const { username } = useLocation().state as LoginForm;
  const { posts, setPosts } = usePosts();

  const [text, setText] = useState<Text>('');
  const [imgUrl, setImgUrl] = useState<ImageUrl>('');

  const AVATAR_API: string = 'https://avatars.dicebear.com/api/adventurer';

  if (!username) {
    return <div>USERNAME NOT FOUND</div>;
  }

  async function handleCreatePost(event: React.MouseEvent<HTMLButtonElement>): Promise<void> {
    event.preventDefault();

    if (!text && !imgUrl) {
      alert('No post body entered.');
    } else {
      const body: PostBody = {
        username: username.trim(),
        text: text?.trim(),
        imgUrl: imgUrl?.trim()
      };

      const post: Post = await createPost(body);
      setPosts([post, ...posts]);

      setText('');
      setImgUrl('');
    }
  }

  return (
    <div className='home'>
      <Header username={username} />
      <div className='feedBody'>
        <div />
        <div className='feed'>
          <div className='createPost'>
            <div className='info'>
              <Avatar src={`${AVATAR_API}/${username}.svg`} />
              <div className='userInfo'>{username}</div>
            </div>
            <form className='form'>
              <input
                type='text'
                value={text}
                onChange={({ target }) => setText(target.value)}
                placeholder={`What's on your mind, ${username}?`}
              />
              <input
                type='text'
                value={imgUrl}
                onChange={({ target }) => setImgUrl(target.value)}
                placeholder='Image URL (optional)'
              />
              <button type='submit' onClick={handleCreatePost} className='sendButton'>
                <SendRoundedIcon />
              </button>
            </form>
          </div>
          {posts?.map((item: Post) => {
            const { username, timestamp, text, imgUrl } = item;

            return (
              <div className='post'>
                <div className='top'>
                  <Avatar src={`${AVATAR_API}/${username}.svg`} />
                  <div className='userInfo'>
                    <span className='username'>{username}</span>
                    <span className='time'>{new Date(timestamp).toDateString()}</span>
                  </div>
                </div>
                {text && (
                  <div className='text'>
                    <p>{text}</p>{' '}
                  </div>
                )}
                {imgUrl && <img src={imgUrl} alt='Post' className='postImg' />}
              </div>
            );
          })}
        </div>
        <div />
      </div>
    </div>
  );
}

export default Home;
