import { ArrowForwardIcon } from '../../utils/icons';
import { useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router';

import './Login.scss';

function Login(): React.ReactElement {
  const navigate: NavigateFunction = useNavigate();
  const [username, setUsername] = useState<string>('');

  function handleClick(): void {

    if (!username) {
      alert('username is required');
      return;
    } 

    navigate('/home', { state: {username} });
  }

  return (
    <div className='login'>
      <div className='dialog'>
        <span className='heading'>
          Hi, <br />
          What's your username?
        </span>
        <input
          type='text'
          placeholder='Username'
          className='username'
          value={username}
          onChange={event => setUsername(event.target.value)}
        />
        <div className='next' onClick={handleClick}>
          {' '}
          Next <ArrowForwardIcon />{' '}
        </div>
      </div>
    </div>
  );
}

export default Login;
