import { Avatar, IconButton } from '@mui/material';
import { NavigateFunction, useNavigate } from 'react-router';

import './Header.scss';
import {
  HomeRoundedIcon,
  SubscriptionsRoundedIcon,
  SupervisedUserCircleRoundedIcon,
  AddRoundedIcon,
  NotificationsRoundedIcon,
  StorefrontIcon,
  ExitToAppRoundedIcon
} from '../../utils/icons';

interface HeaderProps {
  username: string;
}

const AVATAR_API: string = 'https://avatars.dicebear.com/api/adventurer';

function Header({ username }: HeaderProps) {

  const navigate: NavigateFunction = useNavigate();

  function handleLogout(): void {
    navigate('/');
  }

  return (
    <div className='header'>
      <div className='left'>
        <Avatar src='https://avatars.dicebear.com/api/jdenticon/social.svg' />
      </div>
      <div className='center'>
        <div className='activeOption'>
          <HomeRoundedIcon fontSize='large' className='icon' />
        </div>
        <div className='option'>
          <SubscriptionsRoundedIcon fontSize='large' className='icon' />
        </div>
        <div className='option'>
          <StorefrontIcon fontSize='large' className='icon' />
        </div>
        <div className='option'>
          <SupervisedUserCircleRoundedIcon fontSize='large' className='icon' />
        </div>
      </div>
      <div className='right'>
        <div className='info'>
        <Avatar src={`${AVATAR_API}/${username}.svg`} />
          <h4>{username}</h4>
        </div>
        <IconButton>
          <AddRoundedIcon />
        </IconButton>
        <IconButton>
          <NotificationsRoundedIcon />
        </IconButton>
        <IconButton onClick={handleLogout}>
          <ExitToAppRoundedIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default Header;
