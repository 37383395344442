import { useEffect, useState } from 'react';
import { Post } from '../types';
import { getPosts } from '../api';

type UsePosts = {
  posts: Post[];
  loading: boolean;
  setPosts: React.Dispatch<React.SetStateAction<Post[]>>;
}

function usePosts(): UsePosts {
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getPosts().then(posts => {
      setPosts(posts);
    }).finally(() => {
      setLoading(false)
    });
  }, []);

  return {posts, setPosts, loading};
}

export default usePosts;
