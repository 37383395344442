import React from 'react';
import { Home, Login } from '../src/pages';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App(): React.ReactElement {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/home' element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
