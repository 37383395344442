import axios from 'axios';
import { Post, PostBody } from '../types';

const API_URL: string = 'https://social-media.ayushi.workers.dev';
const api = axios.create({ baseURL: API_URL });

export async function getPosts(): Promise<Post[]> {
  try {
    const { data } = await api.get('/posts');

    data.sort((a: Post, b: Post) => b.timestamp - a.timestamp);
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function createPost(body: PostBody): Promise<Post> {
  const { data } = await api.post('/posts', JSON.stringify(body));
  return data;
}
